import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpResponse } from '../../interfaces/http.inteface';
import {
    Lov,
    LovAccount,
    LovAccountData,
    LovAccountPeriod,
    LovAccountRule,
    LovAgent,
    LovApprovalMatrix,
    LovCashBankAccount,
    LovCeding,
    LovClient,
    LovDocument,
    LovEntity,
    LovFinReport,
    LovFixedAsset,
    LovLedger,
    LovLineOfBussines,
    LovObject,
    LovPeriodMaintenance,
    LovReinsurance,
    LovReport,
    LovTableDefinition,
    LovTaxes,
    LovTemplates,
    LovTypeOfCover,
    LovTypeOfInfo,
    LovTypeOfInterest,
    LovTypeOfRisk,
    LovUsers
} from '../../interfaces/lov.interface';
import { map, Observable, filter } from 'rxjs';
import { LOV_URL } from '../../constants/url.const';

@Injectable({
    providedIn: 'root'
})
export class LovService {

    constructor(private http: HttpClient) {
    }

    getLovEntity(): Observable<HttpResponse<LovEntity>> {
        const params = { tableType: 'LOV_ENTITY' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                lov = lov.sort((a, b) => {
                    return a.sequenceNo - b.sequenceNo;
                });
                resp.data = {
                    allList: lov,
                    documentStatusList: lov.filter((l) => l.lovType === 'DOCUMENT_STATUS'),
                    documentTypeList: lov.filter((l) => l.lovType === 'DOCUMENT_TYPE'),
                    entityTypeList: lov.filter((l) => l.lovType === 'ENTITY_TYPE'),
                    transCodeList: lov.filter((l) => l.lovType === 'TRANS_CODE'),
                    approvalDecisionList: lov.filter((l) => l.lovType === 'APPROVAL_DECISION'),
                };
                return resp;
            }));
    }

    getLovCashBankAccount(): Observable<HttpResponse<LovCashBankAccount>> {
        const params = { tableType: 'LOV_CASHBANK' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                lov = lov.sort((a, b) => {
                    return a.sequenceNo - b.sequenceNo;
                });
                resp.data = {
                    allList: lov,
                    cashBankAccountSource: lov.filter((l) => l.lovType === 'ACCOUNT'),
                    cashBankSource: lov.filter((l) => l.lovType === 'BANK'),
                    cashBankStatusSource: lov.filter((l) => l.lovType === 'STATUS'),
                    currencySource: lov.filter((l) => l.lovType === 'CURRENCY')
                };
                return resp;
            }));
    }

    getLovDocument(): Observable<HttpResponse<LovDocument>> {
        const params = { tableType: 'LOV_DOCUMENT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    documentTypeSource: lov.filter((l) => l.lovType === 'DOCUMENT_TYPE'),
                };
                return resp;
            }));
    }

    getLovLineOfBussines(): Observable<HttpResponse<LovLineOfBussines>> {
        const params = { tableType: 'LOV_LOB' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    lobGroupSource: lov.filter((l) => l.lovType === 'LOB_GROUP'),
                };
                return resp;
            }));
    }

    getLovTaxes(): Observable<HttpResponse<LovTaxes>> {
        const params = { tableType: 'LOV_TAX' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    taxTypeSource: lov.filter((l) => l.lovType === 'TAX_TYPE'),
                    statusSource: lov.filter((l) => l.lovType === 'STATUS'),
                    accountSource: lov.filter((l) => l.lovType === 'ACCOUNT')
                };
                return resp;
            }));
    }

    getLovPeriodMaintenance(): Observable<HttpResponse<LovPeriodMaintenance>> {
        const params = { tableType: 'LOV_PERIOD' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    periodSource: lov.filter((l) => l.lovType === 'PERIOD'),
                };
                return resp;
            }));
    }

    getLovTypeOfRisk(): Observable<HttpResponse<LovTypeOfRisk>> {
        const params = { tableType: 'LOV_TOR' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    torGroupSource: lov.filter((l) => l.lovType === 'TOR_GROUP'),
                    torLobSource: lov.filter((l) => l.lovType === 'LOB'),
                    torSectionSource: lov.filter((l) => l.lovType === 'SECTION'),
                };
                return resp;
            }));
    }

    getLovFixedAsset(): Observable<HttpResponse<LovFixedAsset>> {
        const params = { tableType: 'LOV_ASSET' }
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    assetCategorySource: lov.filter((l) => l.lovType === 'ASSET_CATEGORY'),
                    assetClassSource: lov.filter((l) => l.lovType === 'ASSET_CLASS'),
                    assetsGroupSource: lov.filter((l) => l.lovType === 'ASSET_GROUP'),
                    assetsStatusSource: lov.filter((l) => l.lovType === 'ASSET_STATUS'),
                    assetsTypeSource: lov.filter((l) => l.lovType === 'ASSET_TYPE'),
                };
                return resp;
            }));
    }

    getLovTypeOfInfo(): Observable<HttpResponse<LovTypeOfInfo>> {
        const params = { tableType: 'LOV_INFO_TYPES' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    sourceTypeSource: lov.filter((l) => l.lovType === 'SOURCE_TYPE'),
                    interestObjectTypeSource: lov.filter((l) => l.lovType === 'INTEREST_OBJECT_TYPE'),
                };
                return resp;
            }));
    }

    getLovTypeOfInterest(): Observable<HttpResponse<LovTypeOfInterest>> {
        const params = { tableType: 'LOV_TOI' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    toiSectionSource: lov.filter((l) => l.lovType === 'SECTION'),
                    toiLobSource: lov.filter((l) => l.lovType === 'LOB'),
                };
                return resp;
            }));
    }

    getLovTypeOfCover(): Observable<HttpResponse<LovTypeOfCover>> {
        const params = { tableType: 'LOV_TOC' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    tocGroupSource: lov.filter((l) => l.lovType === 'TOC_GROUP'),
                    tocLobSource: lov.filter((l) => l.lovType === 'LOB'),
                };
                return resp;
            }));
    }

    getLovClient(): Observable<HttpResponse<LovClient>> {
        const params = { tableType: 'LOV_CLIENT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data;
                resp.data = {
                    allList: lov,
                    businessLineSource: lov.filter((l) => l.lovType === 'BUSINESS_LINE'),
                    businessEntitySource: lov.filter((l) => l.lovType === 'BUSINESS_ENTITY'),
                    clientCategorySource: lov.filter((l) => l.lovType === 'CLIENT_CATEGORY'),
                    clientClassSource: lov.filter((l) => l.lovType === 'CLIENT_CLASS'),
                    clientGroupSource: lov.filter((l) => l.lovType === 'CLIENT_GROUP'),
                    clientStatusSource: lov.filter((l) => l.lovType === 'CLIENT_STATUS'),
                    clientTypeSource: lov.filter((l) => l.lovType === 'CLIENT_TYPE'),
                    clientGenderSource: lov.filter((l) => l.lovType === 'GENDER'),
                    clientMaritalStatusSource: lov.filter((l) => l.lovType === 'MARITAL_STATUS'),
                    clientNationalitySource: lov.filter((l) => l.lovType === 'NATIONALITY'),
                    clientReligionSource: lov.filter((l) => l.lovType === 'RELIGION'),
                    clientAddressTypeSource: lov.filter((l) => l.lovType === 'ADDRESS_TYPE'),
                    clientBankSource: lov.filter((l) => l.lovType === 'BANK'),
                    clientBankAccountTypeSource: lov.filter((l) => l.lovType === 'BANK_ACCOUNT_TYPE'),
                    clientContactTypeSource: lov.filter((l) => l.lovType === 'CONTACT_TYPE'),
                    clientProvinceSource: lov.filter((l) => l.lovType === 'PROVINCE'),
                    clientCountrySource: lov.filter((l) => l.lovType === 'COUNTRY'),
                    clientIDTypeSource: lov.filter((l) => l.lovType === 'ID_TYPE'),
                };
                return resp;
            }));
    }

    getLovCeding(): Observable<HttpResponse<LovCeding>> {
        const params = { tableType: 'LOV_CEDING' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    cedingCategorySource: lov.filter((l) => l.lovType === 'CEDING_CATEGORY'),
                    cedingClassSource: lov.filter((l) => l.lovType === 'CEDING_CLASS'),
                    cedingGroupSource: lov.filter((l) => l.lovType === 'CEDING_GROUP'),
                    cedingStatusSource: lov.filter((l) => l.lovType === 'CEDING_STATUS'),
                    cedingTypeSource: lov.filter((l) => l.lovType === 'CEDING_TYPE'),
                }
                return resp;
            }));
    }

    getLovReinsurance(): Observable<HttpResponse<LovReinsurance>> {
        const params = { tableType: 'LOV_REINS' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    reinsuranceCategorySource: lov.filter((l) => l.lovType === 'REINS_CATEGORY'),
                    reinsuranceClassSource: lov.filter((l) => l.lovType === 'REINS_CLASS'),
                    reinsuranceGroupSource: lov.filter((l) => l.lovType === 'REINS_GROUP'),
                    reinsuranceStatusSource: lov.filter((l) => l.lovType === 'REINS_STATUS'),
                    reinsuranceTypeSource: lov.filter((l) => l.lovType === 'REINS_TYPE'),
                }
                return resp;
            }));
    }

    getLovAccountData(): Observable<HttpResponse<LovAccountData>> {
        const params = { tableType: 'LOV_ACCOUNT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    accountSource: lov.filter((l) => l.lovType === 'ACCOUNT'),
                }
                return resp;
            }));
    }

    getLovObjects(): Observable<HttpResponse<LovObject>> {
        const params = { tableType: 'LOV_OBJECT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    objectParentSource: lov.filter((l) => l.lovType === 'OBJECT_PARENT'),
                    recordStatusSource: lov.filter((l) => l.lovType === 'RECORD_STATUS'),
                    tableSource: lov.filter((l) => l.lovType === 'TABLE'),
                    objectGroupSource: lov.filter((l) => l.lovType === 'OBJECT_GROUP'),
                    objectTypeSource: lov.filter((l) => l.lovType === 'OBJECT_TYPE'),
                    objectStatusSource: lov.filter((l) => l.lovType === 'OBJECT_STATUS'),
                }
                return resp;
            }));
    }

    getLovAgent(): Observable<HttpResponse<LovAgent>> {
        const params = { tableType: 'LOV_AGENT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                lov = lov.sort((a, b) => {
                    return a.sequenceNo - b.sequenceNo;
                });
                resp.data = {
                    allList: lov,
                    agentCategorySource: lov.filter((l) => l.lovType === 'AGENT_CATEGORY'),
                    agentClassSource: lov.filter((l) => l.lovType === 'AGENT_CLASS'),
                    agentGroupSource: lov.filter((l) => l.lovType === 'AGENT_GROUP'),
                    agentStatusSource: lov.filter((l) => l.lovType === 'AGENT_STATUS'),
                    agentTypeSource: lov.filter((l) => l.lovType === 'AGENT_TYPE'),
                };
                return resp;
            }));
    }

    getLovFinReport(): Observable<HttpResponse<LovFinReport>> {
        const params = { tableType: 'LOV_FIN_REPORT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    accountSource: lov.filter((l) => l.lovType === 'ACCOUNT'),
                    columnOperatorSource: lov.filter((l) => l.lovType === 'COLUMN_OPERATOR'),
                    columnPeriodTypeSource: lov.filter((l) => l.lovType === 'COLUMN_PERIOD_TYPE'),
                    columnTypeSource: lov.filter((l) => l.lovType === 'COLUMN_TYPE'),
                    rowTypeSource: lov.filter((l) => l.lovType === 'ROW_TYPE'),
                    rowOperatorSource: lov.filter((l) => l.lovType === 'ROW_OPERATOR'),
                };
                return resp;
            }));
    }

    getLovUsers(): Observable<HttpResponse<LovUsers>> {
        const params = { tableType: 'LOV_USER' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    userDepartmentSource: lov.filter((l) => l.lovType === 'USER_DEPARTMENT'),
                    userDivisionSource: lov.filter((l) => l.lovType === 'USER_DIVISION'),
                    userLinkTypeSource: lov.filter((l) => l.lovType === 'USER_LINK_TYPE'),
                    userTypeSource: lov.filter((l) => l.lovType === 'USER_TYPE'),
                    userGroupSource: lov.filter((l) => l.lovType === 'USER_GROUP'),
                    userToleSource: lov.filter((l) => l.lovType === 'USER_TOLE'),
                }
                return resp;
            }));
    }

    getLovReport(): Observable<HttpResponse<LovReport>> {
        const params = { tableType: 'LOV_REPORT' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    reportEntitySource: lov.filter((l) => l.lovType === 'ENTITY_TYPE'),
                    reportOutputFormatSource: lov.filter((l) => l.lovType === 'REPORT_OUPUT_FORMAT'),
                    reportParamSource: lov.filter((l) => l.lovType === 'REPORT_PARAM_SOURCE'),
                    reportParamValueSource: lov.filter((l) => l.lovType === 'REPORT_PARAM_VALUE'),
                    reportTypeSource: lov.filter((l) => l.lovType === 'REPORT_SOURCE_TYPE'),
                    reportStatusSource: lov.filter((l) => l.lovType === 'REPORT_STATUS'),
                }
                return resp;
            }));
    }

    getLovTableDefinition(): Observable<HttpResponse<LovTableDefinition>> {
        const params = { tableType: 'LOV_TABLE' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data;
                resp.data = {
                    allList: lov,
                    tableDefinitionStatusSource: lov.filter((l) => l.lovType === 'STATUS'),
                };
                return resp;
            }));
    }

    getLovTemplates(): Observable<HttpResponse<LovTemplates>> {
        const params = { tableType: 'LOV_TEMPLATE' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                const lov = resp.data
                resp.data = {
                    allList: lov,
                    templateTypeSource: lov.filter((l) => l.lovType === 'TEMPLATE_TYPE'),
                    filedCodeSource: lov.filter((l) => l.lovType === 'FIELD_CODE'),
                }
                return resp;
            }));
    }

    getLov(tableType: string, criteria?: string) {
        const params = { tableType, criteria };
        return this.http.get<HttpResponse<Lov[]>>(LOV_URL, { params });
    }

    getLovAccount(): Observable<HttpResponse<LovAccount>> {
        const params = { tableType: 'LOV_ACCOUNT' };
        return this.http.get<any>(LOV_URL, { params }).pipe(
            map((resp) => {
                const lov = resp.data;
                resp.data = {
                    allList: lov,
                    accountList: lov.filter((l) => l.lovType === 'ACCOUNT'),
                    accountCategoryList: lov.filter((l) => l.lovType === 'ACCOUNT_CATEGORY'),
                    accountPositionList: lov.filter((l) => l.lovType === 'ACCOUNT_POSITION'),
                    accountTypeList: lov.filter((l) => l.lovType === 'ACCOUNT_TYPE'),
                };
                return resp;
            }),
        );
    }

    getLovApprovalMatrix(): Observable<HttpResponse<LovApprovalMatrix>> {
        const params = { tableType: 'LOV_APPROVAL_MATRIX' };
        return this.http.get<any>(LOV_URL, { params }).pipe(
            map((resp) => {
                const lov = resp.data;
                resp.data = {
                    allList: lov,
                    approvalModeSource: lov.filter((l) => l.lovType === 'APPROVAL_MODE'),
                    approvalStatusSource: lov.filter((l) => l.lovType === 'APPROVAL_STATUS'),
                    approvalTypeSource: lov.filter((l) => l.lovType === 'APPROVAL_TYPE'),
                    approvalUserSource: lov.filter((l) => l.lovType === 'APPROVAL_USER'),
                    entityTypeSource: lov.filter((l) => l.lovType === 'ENTITY_TYPE'),
                    transGroupSource: lov.filter((l) => l.lovType === 'TRANS_GROUP'),
                };
                return resp;
            }),
        );
    }

    getLovLedger(): Observable<HttpResponse<LovLedger>> {
        const params = { tableType: 'LOV_LEDGER' };
        return this.http.get<any>(LOV_URL, { params }).pipe(
            map((resp) => {
                const lov = resp.data;
                resp.data = {
                    allList: lov,
                    transCodeList: lov.filter((l) => l.lovType === 'TRANS_CODE'),
                    accountList: lov.filter((l) => l.lovType === 'ACCOUNT'),
                };
                return resp;
            }),
        );
    }

    getLovAccountRule(): Observable<HttpResponse<LovAccountRule>> {
        const params = { tableType: 'LOV_ACCOUNT_RULE' };
        return this.http.get<any>(LOV_URL, { params }).pipe(
            map((resp) => {
                const lov = resp.data;
                resp.data = {
                    allList: lov,
                    journalComponentSource: lov.filter((l) => l.lovType === 'JOURNAL_COMPONENT'),
                    journalPositionSource: lov.filter((l) => l.lovType === 'JOURNAL_POSITION'),
                    transCodeSource: lov.filter((l) => l.lovType === 'TRANS_CODE'),
                    transGroupSource: lov.filter((l) => l.lovType === 'TRANS_GROUP'),
                    statusSource: lov.filter((l) => l.lovType === 'STATUS'),
                    accountListSource: lov.filter((l) => l.lovType === 'ACCOUNT'),
                    accountDimensionList: lov.filter((l) => l.lovType === 'ACCOUNT_DIMENSION'),
                };
                return resp;
            }),
        );
    }

    getLovAccountPeriod(): Observable<HttpResponse<LovAccountPeriod>> {
        const params = { tableType: 'LOV_ACCOUNT_PERIOD' };
        return this.http.get<any>(LOV_URL, { params }).pipe(
            map((resp) => {
                const lov = resp.data;
                resp.data = {
                    allList: lov,
                    periodStatusSource: lov.filter((l) => l.lovType === 'PERIOD_STATUS'),
                };
                return resp;
            }),
        );
    }
}
